<template lang="pug">
.center-wrapper
  .center-box.text-center
    v-card(flat
    :loading="isLoading"
    ).mb-3
      v-card-title.background-black
          v-spacer
          img(
            alt="Mr. Rod Logo"
            src="@/assets/mister-rod-no1_old.png"
          ).pa-3.pl-6
          v-spacer
      v-card-title.background-black
        v-spacer
        h2.text--white Veranstaltungen
        v-spacer

      v-card-text.mt-5
        v-alert(type="warning" v-if="!isSSLEnabled" ) Login deaktiviert! Bitte verwenden sie eine gesicherte Verbindung! &nbsp;
          // br
            a(@click="  location.href =   location.href.replace(\"http://\", \"https://\");") Zur sicheren Version
        v-form(v-if="!isLoading")
          v-text-field(name='email', :label="$t('auth.email')", type='text', v-model="email" :disabled="isLoading")
          v-text-field#password( name='password', :label="$t('auth.password')", type='password', v-model="password" :disabled="isLoading" @keyup.enter.native="handleSubmit")
          v-btn(@click.prevent="handleSubmit" color="primary" block) {{$t('auth.login')}}
    v-alert(type="warning" :value="!!loginError") {{$t('auth.error.'+loginError)}}
    v-alert(type="success" :value="!!loginSuccess") {{$t('auth.'+loginSuccess)}}
    //v-alert(type="info" :value="isLoading") {{$t('auth.loading')}}
    v-card(flat v-if="demoAccounts && demoAccounts.length>0" style="position: absolute; left:-250px; top:0; width: 200px; height: auto;").mt-3.mb-3
      v-card-text
        v-list(shaped)
          v-subheader Demo Accounts:
          v-list-item(v-for="account of demoAccounts" :key="account.name")
            v-list-item-title(@click="setAccount(account)").cursor-pointer {{account.name}}

</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { isLoggedInAndSessionNotExpired } from '@/modules/Authentication/helper/Checks'

export default {
  name: 'login',
  metaInfo () { return { title: this.$t('auth.login') } },
  data () {
    return {
      email: '',
      password: '',
      loginError: null,
      loginSuccess: null,
      nextRoute: { name: 'EventsList' },
      demoAccounts: []
    }
  },
  mounted () {
    const vm = this
    if (this.$route.params.error) {
      this.loginError = this.$route.params.error
    } else {
      this.loginError = null
    }
    if (this.$route.params.success) {
      this.loginSuccess = this.$route.params.success
    } else {
      this.loginSuccess = null
    }

    if (this.$route.params.nextUrl) {
      this.nextRoute = this.$route.params.nextUrl
    } else {
      this.nextRoute = { name: 'EventsList' }
    }
    if (isLoggedInAndSessionNotExpired()) {
      vm.$router.push(vm.nextRoute)
    }
    if (process.env.VUE_APP_DEMO_ACCOUNTS) {
      try {
        const values = JSON.parse(process.env.VUE_APP_DEMO_ACCOUNTS)
        this.$set(this, 'demoAccounts', values)
      } catch (e) {
        console.log(e)
      }
    }
  },
  methods: {
    ...mapActions({
      login: 'authentication/login'
    }),
    setAccount (account) {
      this.$set(this, 'email', account.email)
      this.$set(this, 'password', account.password)
      this.handleSubmit()
    },
    handleSubmit () {
      const vm = this
      this.loginError = null
      vm.login({ email: this.email, password: this.password }).then(e => {
        // console.log(e)
        setTimeout(function () {
          vm.$awn.success(vm.$t('auth.loginSuccessful'))
          vm.$router.push(vm.nextRoute)
        }, 5)
      }).catch(e => {
        vm.loginError = e
      })
    }
  },
  computed: {
    ...mapGetters({
      isLoadingAuth: 'authentication/isLoading',
      isLoadingProfile: 'users/isLoading'
    }),
    isSSLEnabled () { return document.location.protocol === 'https:' },
    isLoading () { return this.isLoadingAuth || this.isLoadingProfile }
  }
}
</script>
<style scoped lang="less">
.cursor-pointer{
  cursor: pointer;
}
.background-black{
  background-color: black;
}
.text--white{
  color: white;
}
.center-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  .center-box {
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);
    width: 100%;
    max-width: 400px;
  }
}
</style>
